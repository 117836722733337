import * as opsService from "./Ops";

import { signupUserApi, checkUserApi,getAuthApi } from "../constant/api";

const signupUser = async (data, token) => {
  let result = await opsService.postdata(signupUserApi, data, token);

  return result;
};
const authUser = async (data, token) => {
  let result = await opsService.postdata(getAuthApi, data);

  return result;
};
const checkUser = async (data, token) => {
  let result = await opsService.postdata(checkUserApi, data, token);

  return result;
};
export { checkUser, signupUser,authUser };
