import React, { useState, useEffect } from "react";
import { Navbar } from "../widgets/Navbar";

import { signupUser } from "../../services/auth";
import { toast } from "react-toastify";
import { Emailpattern } from "../pattern/Pattern";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import toastr from 'toastr';
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { Footer } from "../widgets/Footer";
import {Hero} from "../pages/Hero"
export const Register = () => {
  const navigate = useNavigate();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [name, setName] = useState("");
  const [mobile_number, setMobile_number] = useState("+44");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country_code, setCountry_code] = useState();
  const [mobile_numberErr, setMobile_numberErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [nameErr, setNameErr] = useState("");

  const RegisterHandler = async () => {
    if (!name) {
      setNameErr("Name is required");
      return;
    }
    if (!email) {
      setEmailErr("This field is required");
      return;
    }
    if (!Emailpattern.test(email)) {
      setEmailErr("Please enter valid email");
      return;
    }
    if (!mobile_number) {
      setMobile_numberErr("This field is required");
      return;
    }

    const address = localStorage.getItem("address");
    if (!address) {
      return toastr.error("Please connect with metamusk");
    }
    const reffer_from = localStorage.getItem("referral_code");
    let data = {
      name,
      email,
      mobile_number: number,
      country_code,
      address,
      reffer_from,
    };

    const result = await signupUser(data);
    if (result.status) {
      let token = result.token;
      localStorage.setItem("jwtToken", token);
      // login();
      setTimeout(() => {
        // navigate("/#buy-now", { replace: true });
        navigate("/", { replace: true });
        setTimeout(() => {
          window.scrollTo(0, window.scrollY);
        }, 100); // Adjust delay if necessary
      }, 2000);

      toastr.success(result.message);
    } else {
      toastr.error(result.message);
    }
  };

  // useEffect(() => {
  //   const jwtToken = localStorage.getItem("address");
  //   if (jwtToken) {
  //     navigate("/");
  //   }
  // }, [login]);

  const handleChange = (e) => {
    const err = "This field is required";
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      if (value === "") {
        setNameErr("Name is required");
      } else {
        setNameErr("");
      }
    }
    if (name == "email") {
      setEmail(value);
      console.log(value == "", "hhh");
      if (value == "") {
        setEmailErr(err);
      } else {
        if (!Emailpattern.test(value)) {
          setEmailErr("Please enter valid email");
        } else {
          console.log("call");
          setEmailErr("");
        }
      }
    }
    if (name == "mobile_number") {
      setMobile_number(value);

      if (value === "") {
        setMobile_numberErr("Mobile Number is required");
      } else {
        setMobile_numberErr("");
      }
    }
  };

  const validNumber = (e) => {
    if (!/[\d.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleOnChanges = (value, country) => {
    setMobile_number(value);

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    if (!adjustedMobile) {
      setMobile_numberErr("Mobile Number is required");
    } else {
      setMobile_numberErr("");
    }
    setNumber(adjustedMobile);
    setCountry_code("+" + country.dialCode);
  };

  return (
    <>
   
    <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="/assets/img/logo.png" alt="Logo" />
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          <div className="collapse navbar-collapse " id="collapsibleNavbar">
            <ul className="navbar-nav ms-md-auto me-md-4">
              <li className="nav-item ms-auto">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://healix-protocol.gitbook.io/healix-whitepaper"
                >
                  {" "}
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                {/* <a className="nav-link" href="#"> */}
                {/* <w3m-button balance="hide" /> */}
                {/* </a> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Hero/>
      <section className="position-relative p80 pt-4">
      <img src="/assets/img/c1.png" alt="reward" className="position-absolute c1"/>
      <img src="/assets/img/c2.png" alt="reward" className="position-absolute c2"/>
        <div className="container  position-relative ">
        <div className="row align-items-center text-white">
        <div className="col-md-7 m-auto">
          <div className="second-div sign_page_in">
            
           
                <div className="login_box box22 p-md-4 p-2">
                  <h3 className=" mb-md-3">Sign Up</h3>
                  <div class="form-group">
                    <label className="mb-1"> User Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter  User Name"
                      class="input_item"
                      value={name}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{nameErr}</span>
                  </div>
                  <label className="mb-1">Email </label>
                  <div class="form-group position-relative">
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter  Email"
                      class="input_item"
                      value={email}
                      // disabled={inputDisable}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{emailErr}</span>
                  </div>

                  <>
                    <label className="mb-1">Mobile Number</label>
                    <div class="form-group  position-relative">
                      <PhoneInput
                        key={"phoneInput"}
                        country="IND"
                        value={mobile_number}
                        onChange={handleOnChanges}
                        className="input_item"
                        placeholder="Email/Mobile"
                        countryCodeEditable={false}
                        enableSearch={true}
                        inputProps={{
                          autoFocus: true,
                          name: "mobile_number",
                        }}
                        // disabled={disableGetCode}
                      />
                      <span className="text-danger">{mobile_numberErr}</span>
                    </div>
                  </>
                  {/* ) : (
                    ""
                  )} */}

                  <div class="form-group pt-3">
                    <button
                      className="btn w100 btn_new"
                      onClick={RegisterHandler}
                      // disabled={SignupDisable}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4 m-auto ">
                    <img src="/logo512.png" alt="token " class="img-fluid " />
                  </div> */}
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};
