import * as opsService from "./Ops";

import { metaRequestInsertApi, getTransactionApi,generateReferralApi } from "../constant/api";

const metaRequestInsert = async (data, token) => {
  let result = await opsService.postdata(metaRequestInsertApi, data, token);

  return result;
};
const getTransaction = async (data) => {
  let result = await opsService.postdata(getTransactionApi, data);

  return result;
};
const generateReferral = async (data, token) => {
  let result = await opsService.postdata(generateReferralApi, data, token);

  return result;
};

export { metaRequestInsert, getTransaction,generateReferral };
