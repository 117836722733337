import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/pages/Home";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Register } from "./components/auth/Register";
import { TransactionDetails } from "./components/pages/TransactionDetails";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { TermsAndConditions } from "./components/pages/TermsAndConditions";
import { PrivacyPolicy } from "./components/pages/PrivacyPolicy";
function App() {
  toastr.options = {
    positionClass: "toast-top-right",
    hideDuration: 500,
    timeOut: 5000,
  };
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/referral/:walletAddress" element={<Home />} />
          <Route path="/sign-up" element={<Register />} />
          <Route path="/transaction-details" element={<TransactionDetails />} />
          <Route path="/terms-of-use" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/sign-up" element={<SignUp />} />
          <Route path="/transaction-details" element={<TransactionDetails />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/how-to-buy" element={<HowToBuy />} /> */}
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={100000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* <ToastContainer /> */}
    </>
  );
}

export default App;
