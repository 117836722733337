import {
  getStageApi,
  fetchBnbPriceApi,
  fetchEthPriceApi,
  getTotalUsdtApi,
  getPriceApi,
  getUserReferralCode,
} from "../constant/api";
import * as opsService from "./Ops";

const getStage = async (token) => {
  let result = await opsService.getData(getStageApi);
  return result;
};
const fetchBnbPrice = async (token) => {
  let result = await opsService.getData(fetchBnbPriceApi);
  return result;
};
const fetchEthPrice = async (token) => {
  let result = await opsService.getData(fetchEthPriceApi);
  return result;
};
const getTotalUsdt = async (token) => {
  let result = await opsService.getData(getTotalUsdtApi);
  return result;
};

const getPrice = async (token) => {
  let result = await opsService.getData(getPriceApi);
  return result;
};
const getUSerReferral = async (data) => {
  let result = await opsService.postdata(getUserReferralCode, data);
  return result;
};

export {
  getStage,
  fetchBnbPrice,
  fetchEthPrice,
  getTotalUsdt,
  getPrice,
  getUSerReferral,
};
