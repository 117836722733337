import { baseUrl } from "./Baseurl";

export const getStageApi = baseUrl + "/stage-data";
export const fetchBnbPriceApi = baseUrl + "/fetch-bnb-price";
export const fetchEthPriceApi = baseUrl + "/fetch-eth-price";
export const getTotalUsdtApi = baseUrl + "/get-/total-usdt";

export const signupUserApi = baseUrl+"/sign-up-user"
export const checkUserApi = baseUrl+"/check-user"
export const metaRequestInsertApi = baseUrl + "/trx-apply";
export const getAuthApi = baseUrl + "/jwtTokenVerify";
export const getPriceApi = baseUrl + "/get-price";

export const getTransactionApi = baseUrl + "/transaction-get";
export const generateReferralApi = baseUrl + "/generate-referral-code";

export const getUserReferralCode = baseUrl + "/get-user-referral-code";



