// export const baseUrl = "http://192.168.1.40:8083/api/v1"

// export const copyUrl = "http://192.168.1.40:3000/referral/"

// export const baseUrl = "https://healixapi.technoloaderitservices.com/api/v1"

// export const copyUrl = "https://healixprotocol.com/referral/"



export const baseUrl = "https://api.healixprotocol.com/api/v1"

export const copyUrl = "https://healixprotocol.com/referral/"

