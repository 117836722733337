import React from 'react'


export const Footer = () => {
  return (
    <>
<div className="footer">
  <div className="container position-relative">
    <div className="footer-back">
      <h2 style={{}}>Be a part of the Healix Journey!</h2>
      <div className="form-footer">
        <input placeholder="Enter your email address." />
        <img src="/assets/img/right.png" alt="send" />
      </div>
      <div className="d-md-flex mt-5  align-items-end pt-md-5">
        <img src="/assets/img/f-logo.png" alt="logo" className='logo_f' />
        <div className="d-flex  ms-auto">
          <div>
            <a
              className="f_link"
              href="https://healix-protocol.gitbook.io/healix-whitepaper"
              rel="noopener noreferrer"
            >
              Whitepaper
            </a>
          </div>
          <div>
            <a
              className="f_link"
              href="privacy-policy"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
          <div>
            <a
              className="f_link"
              href="/terms-of-use"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </div>
      <div className=" mt-5">
        <p className="fw600 mb-0">*Disclaimer</p>
        <p className="tw-mt-2">
          Digital currencies may be unregulated in your jurisdiction. The value
          of digital currencies may go down as well as up. Profits may be
          subject to capital gains or other taxes applicable in your
          jurisdiction.
        </p>
      </div>
    </div>
  </div>
</div>

    </>
  )
}
