import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../widgets/Navbar";
import { ReferralSection } from "./ReferralSection";
import { Midsection } from "./Midsection";
import { News } from "./News";
import { toast } from "react-toastify";
import toastr from "toastr";
import { Platform } from "./Platform";
import { Faq } from "./Faq";
import PhoneInput from "react-phone-input-2";
import { Footer } from "../widgets/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  generateReferral,
  getTransaction,
  metaRequestInsert,
} from "../../services/transaction";
import { checkUser, authUser } from "../../services/auth";
import copy from "copy-to-clipboard";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import {
  parseUnits,
  formatEther,
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  parseEther,
} from "ethers";

import {
  helixBinanceAddress,
  helixBinanceabi,
  usdt,
  usdtabi,
  helixEthabi,
  usdtEthabi,
  usdtErc,
  binanceChainId,
  ethChainId,
  EthRpcUrl,
  bscRpcUrl,
  explorerUrlBsc,
  explorerUrlEth,
  helixEthAddress,
} from "../../constant";
import {
  fetchBnbPrice,
  fetchEthPrice,
  getStage,
  getTotalUsdt,
  getPrice,
  getUSerReferral,
} from "../../services/user";
import { copyUrl } from "../../constant/Baseurl";

export const Home = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const projectId = "1d8e553b04494fc86b01065d081d9e93";
  const [tokenInput, settokenInput] = useState();
  const [helixToken, setHelixToken] = useState();

  const [loaderStatus, setLoaderStatus] = useState(true);
  const [mainloaderStatus, setMainLoaderStatus] = useState(false);
  const [reffer_from, setReffer_from] = useState("");
  const [copyText, setCopyText] = useState("Copy Link");
  const [buyBtnText, setbuyBtnText] = useState("Select Currency");
  const [selectChains, setSelectChain] = useState("0");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [bnbPrice, setBnbPrice] = useState("");
  const [ethPrice, setEthPrice] = useState("");
  const [stagesData, setStagesData] = useState([]);
  const [tokenPrice, setTokenPrice] = useState("");
  const [stage, setStage] = useState("");
  const [totalAmt, setTotalAmt] = useState("");
  const [totalToken, setTotalToken] = useState("");
  const [totalAmtLimit, setTotalAmtLimit] = useState("");
  const [referral_code, setReferral_code] = useState();
  const [referralBonus, setReferralBonus] = useState();
  const [totalTokenLimit, setTotalTokenLimit] = useState("");

  const bscRpc = bscRpcUrl;
  const EthRpc = EthRpcUrl;

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 100,
    });
  });

  const headingRef = useRef(null);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position and window height

      const scrollTop = window.scrollY;
      if (scrollTop >= 1700) {
        let newScrollPercentage = Math.min(
          100,
          Math.floor(((scrollTop - 1700) / 1700) * 25) * 10
        );
        setScrollPercentage(newScrollPercentage);
      } else {
        setScrollPercentage(0);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Update clip-path and opacity when scroll percentage changes

    if (headingRef.current) {
      headingRef.current.style.clipPath = `polygon(0px 0px, ${scrollPercentage}% 0px, ${scrollPercentage}% 100%, 0% 100%)`;
      headingRef.current.style.opacity = `${scrollPercentage / 10}`;
    }
  }, [scrollPercentage]);

  const imgRef = useRef(null);
  const imgCoinRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position
      const scrollTop = window.scrollY;
      let translateY;
      // Calculate the transform values based on the scroll position
      if (scrollTop > 2500) {
        translateY = -scrollTop / 4; // Adjust the factor as needed
      } else {
        translateY = scrollTop / 100;
      }

      const translateZ = 0; // Adjust the factor as needed

      // Apply the transform to the image
      if (imgRef.current) {
        imgRef.current.style.transform = `translateY(${translateY}px) translateZ(${translateZ}px)`;
      }

      setScrollPosition(scrollTop);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position
      const scrollTop = window.scrollY;
      let translateY;
      // Calculate the transform values based on the scroll position
      if (scrollTop > 2600 && scrollTop < 3500) {
        translateY = -scrollTop / 15;
      } else {
        translateY = 0;
      }

      const translateZ = 0; // Adjust the factor as needed

      // Apply the transform to the image
      if (imgCoinRef.current) {
        imgCoinRef.current.style.transform = `translateY(${translateY}px) translateZ(${translateZ}px)`;
      }

      // setScrollPosition(scrollTop);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
    {
      chainId: ethChainId,
      name: "Ethereum Mainnet",
      currency: "ETH",
      explorerUrl: explorerUrlEth,

      rpcUrl: EthRpcUrl,
    },
  ];
  const metadata = {
    name: "pileupcrypto",
    description: "PUC",
    url: "https://healixprotocol.com/",
    icons: ["https://healixprotocol.com/html/images/Logo.png"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (address) {
        try {
          const result = await generateReferral({ wallet_address: address });
          if (loaderStatus) {
            setReferral_code("");
          } else {
            setReferral_code(result?.data);
          }
        } catch (error) {
          console.error("Error fetching referral:", error);
        }
      }
    };
    fetchData();
    trnsactionGet();
  }, [address, params.walletAddress]);
  const addresses = [helixBinanceAddress, helixEthAddress];

  useEffect(() => {
    if (params.walletAddress) {
      getUSerReferralCode(params.walletAddress);
    } else {
      setLoaderStatus(false);
      setMainLoaderStatus(false);
    }
  }, [params.walletAddress]);
  const getUSerReferralCode = async (referral) => {
    let data = {
      referral_code: referral,
    };

    const result = await getUSerReferral(data);
    if (result.status) {
      localStorage.setItem("referral_code", params.walletAddress);
      setLoaderStatus(false);
      setMainLoaderStatus(false);
    } else {
      setLoaderStatus(true);
      setMainLoaderStatus(true);
    }
  };

  const abis = [helixBinanceabi, helixEthabi];

  const selectAddress = addresses[selectChains] || "";
  const selectAbi = abis[selectChains] || "";

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const optionsMap = {
    0: [
      { value: "0", label: "BNB" },
      { value: "1", label: "USDT (BEP20)" },
    ],
    1: [
      { value: "0", label: "ETH" },
      { value: "1", label: "USDT (ERC20)" },
    ],
  };
  const chainButtons = [
    { index: "0", imgSrc: "/assets/img/bnb.png", alt: "bnb" },
    { index: "1", imgSrc: "/assets/img/eth-white.png", alt: "eth" },
  ];

  const options = optionsMap[selectChains] || [];
  const authUserData = async () => {
    if (address && address) {
      const data = await authUser({ address });
      localStorage.setItem("jwtToken", data.token);
    }
  };
  useEffect(() => {
    authUserData();
  }, [address]);

  const copyToClipboard = (address) => {
    if (address) {
      copy(copyUrl + referral_code);
      setCopyText("Copied");
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const localAddress = localStorage.getItem("address");

      if (isConnected && localAddress !== address) {
        localStorage.setItem("address", address);

        window.location.reload();
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isConnected, address]);

  const selectCurrency = async (e) => {
    try {
      if (isConnected) {
        setSelectedCurrency(e.target.value);
        setHelixToken("");
        let getTokenAddr;
        let provider;

        const rpcUrls = [bscRpc, EthRpc];

        provider = new JsonRpcProvider(rpcUrls[selectChains]);

        const signer = await getSignerOrProvider(true);
        if (e.target.value == "") {
          // setselectedCurrencyUserBalance("");
          setbuyBtnText("Select Currency");
        } else {
          setbuyBtnText("Approve & Buy");
        }

        const tokenAddresses = {
          0: { 1: usdt },
          1: { 1: usdtErc },
        };

        getTokenAddr = tokenAddresses[selectChains]?.[e.target.value] || "";

        // setukcInput("");
        settokenInput("");

        setbuyBtnText("Approve & Buy");
      }
    } catch (err) {}
  };

  const selectChain = async (val) => {
    setSelectChain(val);
    setSelectedCurrency("");
    try {
      if (isConnected) {
        const provider = new JsonRpcProvider(bscRpc);

        const signer = await getSignerOrProvider(true);
        setHelixToken("");
        settokenInput("");
        if (val == "") {
          // setselectedCurrencyUserBalance("");
          setbuyBtnText("Select Chain");
        } else {
          setbuyBtnText("Approve & Buy");
        }
      } else {
        // toast.dismiss();
        // toast.error("Please connect with  wallet");
      }
    } catch (err) {
      // console.error(err);
    }
  };
  const chainImages = ["/assets/img/bnb.png", "/assets/img/eth-white.png"];

  // =================buy==================//
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };
  const getBalance = async () => {
    let provider;

    const rpcProviders = {
      0: bscRpc,
      1: EthRpc,
    };

    const getProvider = (selectChains) => {
      const rpcUrl = rpcProviders[selectChains];
      return new JsonRpcProvider(rpcUrl);
    };

    provider = getProvider(selectChains);

    const signer = await getSignerOrProvider(true);
    let balance = 0;

    const getTokenAddrAndAbi = (selectChains, selectedCurrency) => {
      const tokenDetails = {
        0: {
          // Binance Smart Chain
          1: { addr: usdt, abi: usdtabi },
        },
        1: {
          1: { addr: usdtErc, abi: usdtErc },
        },
      };

      return (
        tokenDetails[selectChains]?.[selectedCurrency] || {
          addr: null,
          abi: null,
        }
      );
    };

    const getDecimalFactor = (selectChains) => {
      return selectChains === 0 ? 10 ** 18 : 10 ** 6;
    };

    const fetchBalance = async (
      provider,
      address,
      getTokenAddr,
      chainAbi,
      selectChains
    ) => {
      if (selectChains === 0 || selectChains === 1) {
        const contract = new Contract(getTokenAddr, chainAbi, provider);
        return await contract.balanceOf(address);
      }

      return 0;
    };

    if (selectedCurrency == null) {
      balance = 0;
    } else if (selectedCurrency == 0) {
      balance = formatEther(await provider.getBalance(address));
    } else {
      const { addr: getTokenAddr, abi: chainAbi } = getTokenAddrAndAbi(
        selectChains,
        selectedCurrency
      );

      if (getTokenAddr && chainAbi) {
        balance = await fetchBalance(
          provider,
          address,
          getTokenAddr,
          chainAbi,
          selectChains
        );
        balance = Number(balance);
        const decimalFactor = getDecimalFactor(selectChains);
        balance = (balance / decimalFactor).toFixed(4);
      }
    }

    if ([2, 3, 4].includes(selectChains) && selectedCurrency != 0) {
      balance = (balance / 10 ** 6).toFixed(4);
    }

    return balance;
  };
  const trnsactionGet = async () => {
    if (address) {
      const resp = await getTransaction({ address });

      if (resp.status) {
        const referralAmt = Number(resp?.referralAmount).toFixed(2);

        setReferralBonus(referralAmt);
        setReffer_from(resp?.reffer_from);
      }
    }
  };

  const getbnbPrice = async () => {
    const resp = await fetchBnbPrice();
    setBnbPrice(resp?.data);
  };
  const getethPrice = async () => {
    const resp = await fetchEthPrice();
    setEthPrice(resp?.data);
  };

  const getPriceData = async () => {
    const resp = await getPrice();

    if (resp.status) {
      setTokenPrice(resp?.tokenPrice);
      setStage(resp?.stage);

      setTotalAmtLimit(resp?.totalAmount);
      setTotalTokenLimit(resp?.totalToken);
    }
  };

  const getTotalUsdtData = async () => {
    const resp = await getTotalUsdt();
    console.log(resp, "jhh");
    setTotalAmt(resp.data);
    setTotalToken(resp.totalToken);
  };
  useEffect(() => {
    const chain = localStorage.getItem("chain");
    const currency = localStorage.getItem("currency");

    const tokenInput = localStorage.getItem("tokenInput");
    const helixToken = localStorage.getItem("helixToken");

    if (chain) {
      setSelectChain(chain);

      localStorage.removeItem("chain");
    }
    if (currency) {
      setSelectedCurrency(currency);
      localStorage.removeItem("currency");
    }
    if (tokenInput) {
      settokenInput(tokenInput);
      localStorage.removeItem("tokenInput");
    }

    if (helixToken) {
      let amt = Number(helixToken).toFixed(2);
      setHelixToken(amt);
      setbuyBtnText("Approve & Buy");
      localStorage.removeItem("helixToken");
    }
  }, [localStorage]);

  useEffect(() => {
    getbnbPrice();

    getTotalUsdtData();
    getethPrice();
    getPriceData();
  }, [tokenInput]);

  const buy = async () => {
    try {
      if (isConnected) {
        if (!selectChains) {
          toastr.dismiss();
          toastr.error("Please select chain");
          return;
        }

        if (!selectedCurrency) {
          // toastr.dismiss();
          // toastr.success('This is a success message!', 'Success');
          toastr.error("Please select Currency", "Error");

          return;
        }

        if (
          tokenInput == undefined ||
          tokenInput.length == 0 ||
          tokenInput <= 0
        ) {
          // toast.dismiss();
          toastr.error("Invalid Amount");

          return;
        }

        if (selectedCurrency == "0") {
          var tokenBalance = await getBalance();

          if (tokenBalance < tokenInput) {
            // toastr.dismiss();
            toastr.error("Insufficient Balance");

            return;
          }

          const signer = await getSignerOrProvider(true);
          const provider = await getSignerOrProvider();
          const chainMap = {
            0: {
              id: binanceChainId,
              message: "Please change network to Binance Smart Chain",
            },
            1: {
              id: ethChainId,
              message: "Please change network to Ethereum Chain",
            },
          };

          const chainInfo = chainMap[selectChains];

          if (chainInfo && chainId !== chainInfo.id) {
            // toast.dismiss();
            toastr.error(chainInfo.message);
            return;
          }

          const res = await checkUser({ address });
          if (res.status) {
            window.addEventListener("beforeunload", handleBeforeUnload);

            const contract = new Contract(selectAddress, selectAbi, provider);
            const valueInWei = parseEther(tokenInput.toString());
            console.log(valueInWei, "JHHUJ");

            let balance;
            if (selectChains == 0) {
              balance = await contract.buyWithBNB({
                from: address,
                value: valueInWei,
              });
            } else if (selectChains == 1) {
              balance = await contract.receiveEther(
                // process.env.REACT_APP_getfundEthreumAddress,
                {
                  from: address,
                  value: valueInWei,
                }
              );
            }

            const transactionHash = balance.hash;
            const reffer_from = localStorage.getItem("referral_code");
            const data = {
              userAddress: address,
              chain: selectChains,
              currency: selectedCurrency,
              amount: tokenInput,
              tokenAmount: helixToken,
              trans_id: transactionHash,
              reffer_from: reffer_from,
              stage: stage,
            };
            const token = localStorage.getItem("jwtToken");
            const result = await metaRequestInsert(data, token);

            if (result.status) {
              window.removeEventListener("beforeunload", handleBeforeUnload);
              // toast.dismiss("");
              toastr.success(result.message);

              setTimeout(() => {
                window.location.reload();
              }, [10000]);
            } else {
              toast.dismiss("");
              // toast.dismiss(result.message);
            }
          } else {
            toastr.error(res.message);
            localStorage.setItem("chain", selectChains);
            localStorage.setItem("currency", selectedCurrency);
            localStorage.setItem("tokenInput", tokenInput);
            localStorage.setItem("helixToken", helixToken);
            // localStorage.setItem(
            //   "selectedCurrencyUserBalance",
            //   selectedCurrencyUserBalance
            // );
            setTimeout(() => {
              navigate("/sign-up");
            }, 2000);
          }
        } else {
          const signer = await getSignerOrProvider(true);
          const provider = await getSignerOrProvider();
          const inputamount = parseUnits(tokenInput, 18);

          let getTokenAddr;
          let updatetAmt = Number(tokenInput);
          let chainnnn;

          if (selectChains == 0) {
            if (chainId != binanceChainId) {
              // toast.dismiss();
              toastr.error("Please change network to binanace smart chain");
              return;
            }
            if (selectedCurrency == "1") {
              getTokenAddr = usdt;
            }
            chainnnn = usdtabi;
          } else if (selectChains == 1) {
            if (chainId != ethChainId) {
              // toast.dismiss();
              toastr.error("Please change network to  Eth chain");
              return;
            }

            if (selectedCurrency == "1") {
              chainnnn = usdtEthabi;

              getTokenAddr = usdtErc;
              updatetAmt = updatetAmt * 10 ** 6;
            }
          }

          const res = await checkUser({ address });
          if (res.status) {
            window.addEventListener("beforeunload", handleBeforeUnload);
            const valueInWeii = parseEther(inputamount.toString());
            let balance;

            let contract = new Contract(getTokenAddr, chainnnn, provider);

            if (selectChains == 0) {
              if (selectedCurrency == 1) {
                const contract = new Contract(getTokenAddr, chainnnn, provider);

                const checkA = (balance = await contract.allowance(
                  address,
                  selectAddress
                ));

                if (Number(checkA) / 10 ** 18 < tokenInput) {
                  const contract = new Contract(
                    getTokenAddr,
                    chainnnn,
                    provider
                  );

                  balance = await contract.approve(
                    selectAddress,
                    "10000000000000000000000000000000000000000000000000000"
                  );
                  await balance.wait();
                  toastr.success(
                    "Approved successfully. Please don't refresh the page.fully. Please don't refresh the page."
                  );
                }
              }
            } else if (selectChains == 1) {
              if (selectedCurrency == 1) {
                const contract = new Contract(getTokenAddr, chainnnn, provider);
                console.log("call`1");
                const checkA = (balance = await contract.allowance(
                  address,
                  selectAddress
                ));
                console.log("calll333", selectAddress);
                console.log(Number(checkA) / 10 ** 6 < Number(tokenInput));
                if (Number(checkA) / 10 ** 6 < Number(tokenInput)) {
                  balance = await contract.approve(
                    selectAddress,
                    "10000000000000000000000000000000000000000000000000000"
                  );
                  await balance.wait();
                  toastr.success(
                    "Approved successfully. Please don't refresh the page."
                  );
                }
              }
            }

            contract = new Contract(selectAddress, selectAbi, provider);

            if (selectChains == 0) {
              if (selectedCurrency == 1) {
                balance = await contract.buyWithToken(inputamount);
              }
            } else {
              console.log(tokenInput, "tokenInput");
              const amttt = Number(tokenInput) * 10 ** 6;
              balance = await contract.receiveToken(usdtErc, amttt);
            }

            const transactionHash = balance.hash;

            const data = {
              userAddress: address,
              chain: selectChains,
              currency: selectedCurrency,
              amount: tokenInput,
              tokenAmount: helixToken,
              trans_id: transactionHash,
              reffer_from: reffer_from,
              stage: stage,
            };
            const token = localStorage.getItem("jwtToken");
            const result = await metaRequestInsert(data, token);

            if (result.status) {
              window.removeEventListener("beforeunload", handleBeforeUnload);
              // toast.dismiss("");
              toastr.success(result.message);

              setTimeout(() => {
                window.location.reload();
              }, [10000]);
            } else {
              // toast.dismiss("");
              toastr.error(result.message);
            }
          } else {
            toastr.error(res.message);
            localStorage.setItem("chain", selectChains);
            localStorage.setItem("currency", selectedCurrency);
            localStorage.setItem("tokenInput", tokenInput);
            localStorage.setItem("helixToken", helixToken);

            setTimeout(() => {
              navigate("/sign-up");
            }, 2000);
          }
        }
      } else {
        // toast.dismiss();
        toastr.error("Please connect with  wallet");
      }
    } catch (err) {
      console.log(err, "hh");
      if (
        err.message.includes(
          `execution reverted: "ERC20: insufficient allowance"`
        )
      ) {
        toastr.error("Insufficient allowance");
      }
      if (err.message.includes(`execution reverted: "invalid Token"`)) {
        toastr.error("Invalid Token");
      }
    }
  };

  const handlechange = async (e) => {
    let tokenAmt = e.target.value;
    let helixToken;
    settokenInput(tokenAmt);

    if (selectChains == 0) {
      if (selectedCurrency == 0) {
        helixToken = (Number(tokenAmt) * Number(bnbPrice)) / Number(tokenPrice);
      } else {
        helixToken = Number(tokenAmt) / Number(tokenPrice);
      }
    } else if (selectChains == 1) {
      if (selectedCurrency == 0) {
        helixToken = (Number(tokenAmt) * Number(ethPrice)) / Number(tokenPrice);
      } else {
        helixToken = Number(tokenAmt) / Number(tokenPrice);
      }
    }

    setHelixToken(helixToken);
  };

  const handleClick = () => {
    if (!isConnected) {
      toastr.info("Please connect your wallet");
    }
  };
  return (
    <>
      {/* {loaderStatus ? (
        <h2> {mainloaderStatus ? "Invalid URL" : ""}</h2>
      ) : ( */}
        <>
          <nav className="navbar navbar-expand-sm nav_fix">
            <div className="container">
              <a className="navbar-brand" href="/" data-aos="flip-left">
                <img src="/assets/img/logo.png" alt="Logo" />
              </a>
              {/* <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon" />
              </button> */}
              <div className="collapse navbar-collapse " id="collapsibleNavbar">
                <ul className="navbar-nav ms-md-auto me-md-4">
                  <li className="nav-item ms-auto" data-aos="fade-down">
                    <a
                      className="nav-link"
                      target="_blank"
                      href="https://healix-protocol.gitbook.io/healix-whitepaper"
                    >
                      {" "}
                      Whitepaper
                    </a>
                  </li>
                  <li className="nav-item" data-aos="fade-down">
                    {/* <a className="nav-link" href="#"> */}
                    <w3m-button balance="hide" />
                    {/* </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <section className="top_box">
            <div className="container mt-4">
              <div className="row align-items-center">
                <div
                  className="  col-md-6 text-center mb-md-0 mb-4"
                  data-aos="fade-right"
                >
                  <img
                    src="/assets/img/logo-l.svg"
                    className="img-fluid"
                    alt="logo"
                  />
                  <div class="audit">
                    <h4>Audited By</h4>
                    <a
                      href="https://skynet.certik.com/projects/healix-protocol"
                      target="_blank"
                    >
                      <img
                        src="/assets/img/audit.png"
                        class="img-fluid"
                        alt="main-logo"
                      />
                    </a>
                  </div>
                </div>
                {/* <div className="col-md-6  ps-md-5 " data-aos="fade-left">
                  <p className="box2p">
                    Global decentralized healthcare ecosystem to make healthcare
                    more affordable and accessible to all, using the power of
                    data science and AI.
                  </p>
                </div> */}
              </div>
            </div>
          </section>
          <section class="pt-4 ">
            <div class="container">
              <div class="hero mb-5" data-aos="fade-right">
                <img src="/assets/img/ubg.png" alt="logo" />
                <div class="hero-box" data-aos="fade-up">
                  <img src="/assets/img/users.png" alt="logo" class="adults" />
                  <h1>
                    <div data-aos="fade-up">Multiply Health</div>

                    <div data-aos="fade-up" data-aos-offset="300">
                      Create Wealth
                    </div>
                  </h1>
                  <img src="/assets/img/aero.svg" alt="logo" class="right" />
                  <div class="button-style" data-aos="fade-up">
                    <a
                      href="https://healix-protocol.gitbook.io/healix-whitepaper"
                      target="_blank"
                    >
                      {" "}
                      <button>Whitepaper</button>
                    </a>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </section>
          <section className="p80  join_the">
            <div className="container">
              <div className="row ">
                <div className="  col-md-4 mb-4">
                  <h2 className="pe-md-4" data-aos="fade-right">
                    Join the wellness ecosystem with our HLX token presale!
                  </h2>
                  <p className="p_join" data-aos="fade-right">
                    Healix is the AI-integrated, health and wellness ecosystem
                    which allows you to invest and earn right away. Simplify
                    your journey to optimal health and well-being with HLX, the
                    key to unlocking a world of wellness possibilities.
                  </p>
                </div>
                <div className="col-md-8 mb-4" data-aos="fade-up">
                  <div className="ex_box_in position-relative  second-div">
                    <img
                      src="/assets/img/setting.svg"
                      alt="setting"
                      className="setting rotating"
                    />
                    <div className="profile d-flex align-items-center mb-4">
                      <img src="/assets/img/profile.png" alt="profile" />
                      <div className="user-deatils ps-3">
                        <h6>XYZ</h6>
                        <p className="m-0">Welcome back</p>
                      </div>
                    </div>
                    <div className="ps-md-5">
                      <div className="deatils d-flex align-items-center mb-3">
                        <h2 className="mb-0">Healix Presale</h2>
                        <p className="ms-auto mb-0">Stage {stage}</p>
                      </div>
                      <div className="raised">
                        <h3>USD Raised:</h3>
                        <h4>{parseInt(totalAmt).toLocaleString()}</h4>
                        <h5>/${parseInt(totalAmtLimit).toLocaleString()}</h5>
                      </div>
                      <div className="raised">
                        <h3>Tokens Sold:</h3>
                        <h4> {parseInt(totalToken).toLocaleString()}</h4>
                        <h5>
                          /
                          {totalTokenLimit
                            ? parseInt(totalTokenLimit).toLocaleString()
                            : "0"}
                        </h5>
                      </div>

                      <h3 className="mb-3">
                        1 HLX = {tokenPrice ? tokenPrice : "0"}
                      </h3>

                      <div className="progress mb-4">
                        <div
                          className="progress-bar"
                          style={{
                            width:
                              totalAmt && totalAmtLimit
                                ? `${(totalAmt * 100) / Number(totalAmtLimit)}%`
                                : "0%",
                          }}
                        >
                         
                        </div>
                        <div className="w50p">{totalAmt
                            ? `${parseFloat(
                                (totalAmt * 100) / Number(totalAmtLimit)
                              ).toFixed(0)}%`
                            : "0%"}</div>
                        
                      </div>
                      <div className="mt-2">
                        <div className="tab_btn d-flex mb-4">
                          {chainButtons.map(({ index, imgSrc, alt }) => (
                            <button
                              key={index}
                              onClick={() => selectChain(index)}
                              className={selectChains === index ? "active" : ""}
                            >
                              <img src={imgSrc} alt={alt} />
                            </button>
                          ))}
                          {/* <button className="active">
                        <img src="/assets/img/bnb.png" alt="bnb" />
                      </button>
                      <button className>
                        <img src="/assets/img/eth-white" alt="usdt" />
                      </button> */}
                        </div>
                        <div className="form-group ex_input_box position-relative">
                          <input
                            // step="0.1"
                            // min={0}
                            onChange={handlechange}
                            placeholder=""
                            id="busdAmtIdo"
                            className="input_item"
                            value={tokenInput}
                          />
                          <img
                            src={chainImages[selectChains]}
                            alt="Chain Logo"
                            className="in_icon position-absolute"
                          />
                          <select
                            className="select_dark"
                            id="currency_id"
                            onChange={(e) => selectCurrency(e)}
                            name="currency_id"
                            value={selectedCurrency}
                          >
                            <option value>Select Currency</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group ex_input_box position-relative">
                          <input
                            placeholder="Max HLX  Received"
                            className="input_item"
                            min={0}
                            value={helixToken}
                            disabled
                            id="tokenAmtIdo2"
                          />
                          <img
                            src="/assets/img/hp.png"
                            alt="coin "
                            className="in_icon position-absolute"
                          />
                        </div>
                        <button
                          className="btn btn1 w100 ttu mb-3"
                          onClick={buy}
                        >
                          {buyBtnText}
                        </button>
                        {/* {isConnected ? (
                          <a
                            href="/transaction-details"
                            className="btn btn2 w100 mb-4"
                            id="btn-connect-wallet"
                          >
                            Transaction Dashboard
                          </a>
                        ) : (
                          <a
                            href="#"
                            className="btn btn2 w100 mb-4"
                            id="btn-connect-wallet"
                          >
                            Transaction Dashboard
                          </a>
                        )} */}

                        {isConnected ? (
                          <a
                            href="/transaction-details"
                            className="btn btn2 w100 mb-4"
                            id="btn-connect-wallet"
                          >
                            Transaction Dashboard
                          </a>
                        ) : (
                          <a
                            href="#"
                            className="btn btn2 w100 mb-4"
                            id="btn-connect-wallet"
                            onClick={(e) => handleClick()} // Prevent default action if not connected
                          >
                            Transaction Dashboard
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>
            <section className="p80  text-center pb-0 position-relative">
              <div className="container ani">
                <h2 className={`hadding mb-4 mb-md-5 `}>
                  <span class="position-relative d-inline-block">
                    HLX Ambassador Program
                    <div ref={headingRef} style={{ opacity: 0 }}>
                      HLX Ambassador Program
                    </div>
                  </span>
                </h2>
                <img
                  src="/assets/img/ambassador.png"
                  alt="ambassador"
                  className="w100"
                  data-aos="zoom-in"
                />
              </div>
            </section>
            <section className="p80 re_ovh position-relative pb-0">
              <img
                src="/assets/img/ovh_bg.png"
                alt="reward"
                class="position-absolute  ovh_bg scroll-image"
                ref={imgRef}
              />
              <div className="container position-relative">
                <div className="earn_box position-relative">
                  <img
                    src="/assets/img/coin.png"
                    alt="logo"
                    className="coin_r scroll-image"
                    ref={imgCoinRef}
                  />
                  <div className="row align-items-center">
                    <div className="col-md-5 text-center pe-md-5">
                      <h4 className="mb-4">
                        Earn more HLX by <br />
                        referring your friends and community
                      </h4>
                      <p className="tw-opacity-70 mb-4">
                        Share your unique link below and recieve 5% of all
                        transactions realized with your link instantly!
                      </p>
                      <div className="refral_input mb-4">
                        <input
                          className="input-field"
                          placeholder={
                            address
                              ? `${copyUrl}${referral_code}`
                              : " Connect Wallet for Referral Link"
                          }
                          value={
                            address
                              ? `${copyUrl}${referral_code}`
                              : " Connect Wallet for Referral Link"
                          }
                          readOnly
                        />
                        <button
                          className="btn"
                          onClick={() => {
                            copyToClipboard(address);
                          }}
                        >
                          {copyText}
                        </button>
                      </div>
                      <div className="d-inline-block ">
                        <w3m-button balance="hide" />
                      </div>
                      <div className="mb-4 mt-2">
                        <p className="tw-text-xs">
                          Share it directly on social media!
                        </p>
                      </div>
                      <div className="text-center social">
                        {isConnected ? (
                          <a
                            href={`https://twitter.com/intent/tweet?text=Hello%20guys%20,%20Check%20Out%20My%20Referral%20Link:%20https://healixprotocol.com/referral/${referral_code}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-twitter-x" />
                          </a>
                        ) : (
                          <a onClick={handleClick}>
                            <i className="bi bi-twitter-x" />
                          </a>
                        )}

                        {isConnected ? (
                          <a
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=https://healixprotocol.com/referral/${referral_code}&title=Check%20Out%20My%20Referral%20Link&summary=Hello%20guys,%20Check%20Out%20My%20Referral%20Link:%20https://healixprotocol.com/referral/${referral_code}

`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-linkedin" />
                          </a>
                        ) : (
                          <a onClick={handleClick}>
                            <i className="bi bi-linkedin" />
                          </a>
                        )}

                        {isConnected ? (
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=https://healixprotocol.com/referral/${referral_code}
`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-facebook" />
                          </a>
                        ) : (
                          <a onClick={handleClick}>
                            <i className="bi bi-facebook" />
                          </a>
                        )}

                        {isConnected ? (
                          <a
                            href={`https://t.me/share/url?url=https://healixprotocol.com/referral/${referral_code}&text=Hello%20guys,%20Check%20Out%20My%20Referral%20Link!
`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-telegram" />
                          </a>
                        ) : (
                          <a onClick={handleClick}>
                            <i className="bi bi-telegram" />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <h1 className="mb-4">
                        Earn a 5%
                        <br /> bonus from all
                        <br /> referred
                        <br /> users!
                      </h1>
                      <p className="p_join">
                        Share your unique link with friends, family and your
                        network, to receive 5% of all of their transactions in
                        HLX tokens.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Midsection />
          <News />
          <Platform />
          <Faq />
          <Footer />
        </>
      {/* )} */}
    </>
  );
};
