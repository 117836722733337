import React from 'react'
import { Hero } from './Hero'
import { Footer } from '../widgets/Footer'

export const PrivacyPolicy = () => {
  return (
    <>
             <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="/assets/img/logo.png" alt="Logo" />
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          <div className="collapse navbar-collapse " id="collapsibleNavbar">
            <ul className="navbar-nav ms-md-auto me-md-4">
              <li className="nav-item ms-auto">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://healix-protocol.gitbook.io/healix-whitepaper"
                >
                  {" "}
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                {/* <a className="nav-link" href="#"> */}
                {/* <w3m-button balance="hide" /> */}
                {/* </a> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Hero/>
      <section className="position-relative p80 ">
      <img src="/assets/img/c1.png" alt="reward" className="position-absolute c1"/>
      <img src="/assets/img/c2.png" alt="reward" className="position-absolute c3"/>
      <div className="container relative tw-z-10">
  <div className="in_page_content position-relative ">
    <h1 className="tw-text-5xl tw-my-10">Privacy Policy</h1>
    <p>
      This Privacy Policy ("Policy") outlines how Healix ("we," "us," or "our")
      collects, uses, and safeguards personal information when you interact with
      our website, HealixProtocol.com. By accessing or using our website, you
      consent to the collection and utilization of your personal information as
      detailed in this Policy.
    </p>
    <h2 className="tw-text-xl tw-font-bold tw-mb-4">Information Collection</h2>
    <p>
      During your visit to our website, we may gather the following types of
      information:
    </p>
    <ul className="tw-list-disc tw-ml-4 tw-mb-8">
      <li>
        <strong>1.1 Personal Information:</strong> This encompasses details like
        your name, email address, contact information, and any other data
        voluntarily provided through contact forms or account registration.
      </li>
      <li>
        <strong>1.2 Log Data:</strong> We capture information that your browser
        transmits when you browse our website, such as your IP address, browser
        type, operating system, visited pages, session duration, and other
        pertinent statistics.
      </li>
      <li>
        <strong>1.3 Cookies and Similar Technologies:</strong> To enrich your
        browsing experience, we utilize cookies and similar tracking
        technologies. Please refer to our Cookies Policy for comprehensive
        information.
      </li>
    </ul>
    <h2 className="tw-text-xl tw-font-bold tw-mb-4">Use of Information</h2>
    <p>The information we collect may be used for the following purposes:</p>
    <ul className="tw-list-disc tw-ml-4 tw-mb-8">
      <li>
        <strong>2.1 Service Provision and Enhancement:</strong> We leverage your
        information to comprehend your requirements and elevate your browsing
        experience on our website. This includes analyzing usage patterns,
        tailoring content, and refining website functionality.
      </li>
      <li>
        <strong>2.2 Communication:</strong> Your contact details may be used to
        address your queries, offer customer support, dispatch important
        notifications, and apprise you about our services and promotions.
      </li>
      <li>
        <strong>2.3 Personalization:</strong> We may utilize your information to
        customize your interaction with our website, such as displaying
        pertinent content or advertisements.
      </li>
      <li>
        <strong>2.4 Legal Compliance:</strong> Your information may be processed
        to adhere to applicable laws, regulations, or legal requests.
      </li>
    </ul>
    <h2 className="tw-text-xl tw-font-bold tw-mb-4">
      Data Security and Retention
    </h2>
    <p>
      We are committed to safeguarding your personal information and employ
      suitable technical and organizational measures to thwart unauthorized
      access, alteration, disclosure, or destruction of your data. Your personal
      information is retained only for the duration necessary to fulfill the
      purposes outlined in this Policy, unless a longer retention period is
      mandated or allowed by law.
    </p>
    <h2 className="tw-text-xl tw-font-bold tw-mb-4">Third-Party Disclosure</h2>
    <p>
      We may share your personal information with trusted third parties who aid
      us in operating our website or delivering services to you. These parties
      are obliged to uphold the confidentiality of your information and utilize
      it solely for the designated purposes. Additionally, we may divulge your
      information to comply with applicable laws, regulations, or legal
      proceedings, or to safeguard our rights, property, or safety.
    </p>
    <h2 className="tw-text-xl tw-font-bold tw-mb-4">Your Choices and Rights</h2>
    <p>
      You possess certain rights pertaining to your personal information. You
      can request access, rectification, or erasure of your information, and you
      may object to or restrict certain processing activities. To exercise these
      rights, kindly contact us using the provided information below.
    </p>
    <h2 className="tw-text-xl tw-font-bold tw-mb-4">Updates to this Policy</h2>
    <p>
      We reserve the right to revise this Policy periodically. Any alterations
      will be published on our website, and the "Last updated" date at the top
      of the Policy will be amended accordingly. It is your responsibility to
      periodically review this Policy to stay abreast of our handling of your
      personal information.
    </p>
    <h2 className="tw-text-xl tw-font-bold tw-mb-4">Contact Information</h2>
    <p>
      For inquiries, concerns, or requests concerning this Privacy Policy or our
      practices, please reach out to us at{" "}
      <a href="mailto:support@healixprotocol.com" className="tw-text-blue-500">
        support@healixprotocol.com
      </a>
      .
    </p>
  </div>
</div>

      </section>
      <Footer/>
    </>
  )
}
