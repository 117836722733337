import React from "react";

export const Navbar = () => {
  return (
    <>
    
      <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="/assets/img/logo.png" alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse " id="collapsibleNavbar">
            <ul className="navbar-nav ms-md-auto me-md-4">
              <li className="nav-item ms-auto">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://healix-protocol.gitbook.io/healix-whitepaper"
                >
                  {" "}
                  Whitepaper
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Connect
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <section className="top_box">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="  col-md-6 text-center mb-md-0 mb-4">
              <img
                src="/assets/img/logo-l.svg"
                className="img-fluid"
                alt="logo"
              />
            </div>
            {/* <div className="col-md-6 ">
              <p>
                Global decentralized healthcare ecosystem to make healthcare
                more affordable and accessible to all, using the power of data
                science and AI.
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
