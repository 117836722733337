import React from 'react'

export const Faq = () => {
  return (
    <>
    <section className="p60 faq  position-relative">
  <img src="/assets/img/faq.png" alt="reward" className="position-absolute faq1" />
  <img src="/assets/img/faq2.png" alt="reward" className="position-absolute faq2" />
  <div className="container position-relative">
    <div className="row">
      <div className="col-md-11 m-auto">
        <h2 className="hadding"> FAQ</h2>
        <h3 className="mb-3">New to crypto? </h3>
        <div className="accordion pb-4" id="regularAccordionRobots">
          <div className="accordion-item">
            <h2 id="regularHeadingFirst" className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseFirst"
                aria-expanded="true"
                aria-controls="regularCollapseFirst"
              >
                How to buy $HLX using my desktop?
              </button>
            </h2>
            <div
              id="regularCollapseFirst"
              className="accordion-collapse collapse show"
              aria-labelledby="regularHeadingFirst"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                <ol className="tw-list-decimal tw-pl-4 tw-ml-4 tw-space-y-2">
                  <li>
                    First, ensure you have the MetaMask wallet installed as a
                    browser extension in order to connect your wallet to the
                    platform:
                    <a
                      href="https://metamask.io/download/"
                      className="tw-text-blue-500 tw-underline"
                    >
                      https://metamask.io/download/
                    </a>
                  </li>
                  <li>
                    Once connected, you can buy BNB or a stablecoin such as USDT
                    via card. Just click on "Buy" and it will open the MetaMask
                    marketplace, at:
                    <a
                      href="https://portfolio.metamask.io/"
                      className="tw-text-blue-500 tw-underline"
                    >
                      https://portfolio.metamask.io/
                    </a>
                  </li>
                  <li>
                    Once you have the necessary funds in your wallet, head over
                    to the Healix platform at:{" "}
                    <a
                      href="https://healixprotocol.io"
                      className="tw-text-blue-500 tw-underline"
                    >
                      https://healixprotocol.io
                    </a>
                  </li>
                  <li>
                    Click on the "Buy Now" button to enable Healix to connect
                    with your wallet. Once connected, you can buy $HLX tokens
                    using a selection of currencies from the BNB chain.
                  </li>
                  <li>
                    Choose the amount of $HLX tokens you wish to buy or the
                    amount of your assets you wish to spend and click "Buy now".
                    If you don't have enough BNB, please transfer it from any
                    crypto exchange to your wallet address or buy more through
                    MetaMask marketplace.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="regularHeadingSecond">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond"
                aria-expanded="false"
                aria-controls="regularCollapseSecond"
              >
                How to buy $HLX using my mobile?
              </button>
            </h2>
            <div
              id="regularCollapseSecond"
              className="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                <ol className="tw-list-decimal tw-pl-4 tw-ml-4 tw-space-y-2">
                  <li>
                    If you are purchasing on mobile, we recommend using Trust
                    Wallet and connecting through the WalletConnect:
                    <a
                      href="https://community.trustwallet.com/t/how-to-use-walletconnect-with-trust-wallet/36247"
                      className="tw-text-blue-500 tw-underline"
                    >
                      https://community.trustwallet.com/t/how-to-use-walletconnect-with-trust-wallet/36247
                    </a>
                  </li>
                  <li>
                    Download the TrustWallet app onto your mobile device from
                    the relevant app store (Apple App Store, Google Play Store,
                    etc).
                  </li>
                  <li>
                    Once your wallet is activated, you need to ensure you have
                    the BNB Chain available in your wallet. If you don’t, you
                    can add it to your wallet by following the steps at:
                    <a
                      href="https://community.trustwallet.com"
                      className="tw-text-blue-500 tw-underline"
                    >
                      https://community.trustwallet.com
                    </a>
                  </li>
                  <li>
                    If you don't have any assets in the wallet, please transfer
                    them from any crypto exchange to your wallet address or buy
                    more through Trust Wallet marketplace simply by clicking on
                    the “Buy” button. Once you have the necessary funds in your
                    wallet, head over to the Healix platform at:
                    <a
                      href="https://healixprotocol.io"
                      className="tw-text-blue-500 tw-underline"
                    >
                      https://healixprotocol.io
                    </a>
                  </li>
                  <li>
                    Click on the "Buy Now" button to enable Healix to connect
                    with your wallet. Once connected, you can buy $HLX tokens
                    using a selection of currencies from the ETH chain.
                  </li>
                  <li>
                    Choose the amount of $HLX tokens you wish or select how much
                    assets you wish to spend and click "Buy now".
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <h3 className="mb-3 mt-4">About Healix </h3>
          <div className="accordion-item">
            <h2 className="accordion-header" id="regularHeadingSecond2">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond2"
                aria-expanded="false"
                aria-controls="regularCollapseSecond2"
              >
                How does Healix use AI and IoT for health monitoring?
              </button>
            </h2>
            <div
              id="regularCollapseSecond2"
              className="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond2"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                By integrating cutting-edge technologies such as Artificial
                Intelligence (AI) and Internet of Things (IoT), the platform
                empowers users to monitor and manage their health with
                unprecedented accuracy and efficiency.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="regularHeadingSecond3">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond3"
                aria-expanded="false"
                aria-controls="regularCollapseSecond3"
              >
                Why did Healix choose the BNB chain?
              </button>
            </h2>
            <div
              id="regularCollapseSecond3"
              className="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond3"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                The decision to build the HLX token ecosystem on Binance Smart
                Chain (BSC) stems from its unparalleled advantages over other
                blockchain infrastructures. BSC is the foundation for Healix
                Protocol, providing a robust and efficient platform to support
                seamless transactions, decentralized governance, and innovative
                incentive mechanisms.
              </div>
            </div>
          </div>
          {/* <div className="accordion-item">
            <h2 className="accordion-header" id="regularHeadingSecond4">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond4"
                aria-expanded="false"
                aria-controls="regularCollapseSecond4"
              >
                Can we earn by being a part of Healix?
              </button>
            </h2>
            <div
              id="regularCollapseSecond4"
              className="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond4"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                Yes, The Health Staking Contract is a mechanism designed to
                empower users in the health and wellness space to stake their
                HLX tokens and receive rewards generated from a combination of
                the staking rewards pool and a percentage of contributions from
                individuals actively pursuing their health and fitness goals
                through the Healix Ecosystem.
              </div>
            </div>
          </div> */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="regularHeadingSecond4">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond4"
                aria-expanded="false"
                aria-controls="regularCollapseSecond4"
              >
                How can we improve our health by using Healix?
              </button>
            </h2>
            <div
              id="regularCollapseSecond4"
              className="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond4"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                Within the HEALIX platform, the Health Quotient (HQ) stands as a
                novel feature, providing users with real-time insights into
                their overall health and wellness. HQ is a dynamic score derived
                from a comprehensive analysis of various biomarkers and health
                parameters, enabling individuals to gauge their health status
                with precision and accuracy.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="regularHeadingSecond5">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond5"
                aria-expanded="false"
                aria-controls="regularCollapseSecond5"
              >
                What are the utilities of the HLX token?
              </button>
            </h2>
            <div
              id="regularCollapseSecond5"
              className="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond5"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                The HLX token lies at the heart of the Healix Protocol, serving
                as a versatile and essential component of the ecosystem. With a
                range of use cases designed to incentivize engagement,
                facilitate transactions, and enhance platform security, the HLX
                token plays a pivotal role in driving the adoption and growth of
                the Healix platform.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="regularHeadingSecond6">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond6"
                aria-expanded="false"
                aria-controls="regularCollapseSecond6"
              >
                Does Healix provide data security?
              </button>
            </h2>
            <div
              id="regularCollapseSecond6"
              className="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond6"
              data-bs-parent="#regularAccordionRobots"
            >
              <div className="accordion-body">
                Yes, Healix addresses data silos and privacy concerns by
                implementing robust data security protocols and empowering users
                to control their health data. Through encrypted blockchain
                technology and user-centric data management tools, Healix
                ensures the confidentiality and integrity of personal health
                information, fostering trust and compliance with privacy
                regulations.
              </div>
            </div>
          </div>
        </div>
        <div className="join mt-5 text-center">
          <div className="text-center social mb-4" data-aos="fade-up">
            <a
              href="https://x.com/healixprotocol?s=21&t=WQhFDvd-ChY9CtQvop-K3w"
              target="_blank"
            >
              <i className="bi bi-twitter-x" />
            </a>
            <a href="https://t.me/healixprotocol" target="_blank">
              <i className="bi bi-telegram" />
            </a>
            <a
              href="https://www.instagram.com/healixprotocol/?igsh=azZieDlteWFmYTF0"
              target="_blank"
            >
              {" "}
              <i className="bi bi-instagram" />
            </a>
            <a href="https://medium.com/@healixprotocol" target="_blank">
              <i className="bi bi-medium" />
            </a>
          </div>
          <h1 className="hadding mb-4" data-aos="fade-up">
            Be a part of the Healix <br />
            Journey!
          </h1>
          <p data-aos="fade-up">
            Healix employs advanced cryptographic techniques to secure and
            protect sensitive health data. The decentralized nature of the
            blockchain ensures that users have control over their medical
            information while maintaining privacy and confidentiality.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  )
}
