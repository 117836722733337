import React from "react";

export const Hero = () => {
  return (
    <>
      <section className="top_box">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="  col-md-6 text-center mb-md-0 mb-4">
              <img
                src="/assets/img/logo-l.svg"
                className="img-fluid"
                alt="logo"
              />
              <div class="audit">
                <h4>Audited By</h4>
                <a
                  href="https://skynet.certik.com/projects/healix-protocol"
                  target="_blank"
                >
                  <img
                    src="/assets/img/audit.png"
                    class="img-fluid"
                    alt="main-logo"
                  />
                </a>
              </div>
            </div>

            {/* <div className="col-md-6  ps-md-5 " >
              <p className="box2p">
                Global decentralized healthcare ecosystem to make healthcare
                more affordable and accessible to all, using the power of data
                science and AI.
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
