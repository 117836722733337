import React from "react";

export const Midsection = () => {
  return (
    <>
      <section className="p80 ovh position-relative">
      <img src="/assets/img/web3.png" alt="reward" class="position-absolute  web3"/>
        <div className="container position-relative">
          <div className="parent-container">
            <div
              className="second-div2 trans"
              data-aos="fade-right"  
            >
              <h2  >HLX to lead Web3 economy in healthcare</h2>
              <p  >
                With an estimated value exceeding USD 8 trillion, the healthcare
                sector is one of the largest and most rapidly evolving
                industries worldwide. While the overall healthcare market is
                vast and multifaceted, the preventive healthcare space stands
                out as a particularly promising segment poised for exponential
                growth.
              </p>
              <p  >
                Preventive healthcare technologies and services industry size
                was USD 251.2 billion in 2023 and is estimated to reach USD
                605.3 billion by 2032 owing to the growing prevalence of chronic
                diseases, such as diabetes and obesity. HLX Token is uniquely
                poised to grab this market.
              </p>
            </div>
            <div className="first-div1">
              <div className="d-flex mb-5">
              <div
                  className="sector"
                  data-aos="fade-left"
                  
                >
                  <h1>$8T</h1>
                  <p>Estimated value of the healthcare sector</p>
                </div>
                <div
                  className="sector"
                  data-aos="fade-left" 
                >
                  <h1>$251B</h1>
                  <p>
                    Current preventive healthcare technologies and services
                    industry size
                  </p>
                </div>
              </div>
              <div className="threex" data-aos="fade-up-left">
                <img src="/assets/img/x3.png" alt="3x" className="img-fluid" />
                <p className="mt-4">
                  Growth of the preventive healthcare technologies and services
                  industry size owing to the growing prevalence of chronic
                  diseases, such as diabetes and obesity. HLX Token is uniquely
                  poised to grab this market.
                </p>
              </div>
            </div>
          </div>
          <div className="section-3 covered_section  ">
            <div className="cover">
              <div
                className="globalbox"
                data-aos="fade-left"
                data-aos-anchor-placement="top-center"
                data-aos-duration={1500}
              >
                <p>
                  Connecting health and wellness institutions and practitioners
                  with health seekers, which provides options for seekers.
                </p>
                <hr className="globalbox-line" />
                <h2 data-aos="fade-up">Global Blockchain based ecosystem</h2>
              </div>
              <div className="healix-box">
                <img
                  src="/assets/img/wh.png"
                  alt="brand"
                  style={{ filter: "blur(0px)", transform: "none" }}
                />
              </div>
            </div>
            <div
              className="Engagement"
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              data-aos-duration={1500}
            >
              <div className="powerful">
                <h2>
                  <div>Powerful Incentivized</div>
                  <div>Engagement</div>
                </h2>
              </div>
              <div className="engage">
                <p>Engage with rewards and loyalty programs.</p>
              </div>
            </div>
            <div className="global">
              <div className="real-time">
                <h2>Real-Time Monitoring &amp; Guidance</h2>
                <hr className="globalbox-line" />
                <p>Instant health monitoring personalized advice.</p>
              </div>
              <div
                className="Global-Community"
                data-aos="fade-left"
                data-aos-anchor-placement="top-center"
                data-aos-duration={1500}
              >
                <h2>
                 
                    Global Web3
                <br/>
                 Community 
                </h2>
                <hr className="globalbox-line-white" />
                <p>
                  Join our global Web3 community, where innovation,
                  collaboration, and blockchain technology unite to shape the
                  future.
                </p>
              </div>
            </div>
            <div className="cover2">
              <div className="Healix-box2" data-aos="flip-left" />
              <div
                className="globalbox2"
                data-aos="fade-left"
                data-aos-anchor-placement="top-center"
                data-aos-duration={1500}
              >
                <p>
                  Crafting a global healthcare network that prioritizes
                  affordable, accessible self-care for all, powered by data
                  science and AI.
                </p>
                <hr className="globalbox-line" />
              </div>
            </div>
            <div className="Benefits">
              <div className="Partner-Benefits">
                <p>
                  Making it beneficial for users and health insurance companies.
                </p>
                <hr className="globalbox-line-white" />
                <h2>
                  Exclusive <br/>
                 Partner Benefits 
                </h2>
              </div>
              <div
                className="Global-Healthcare"
                data-aos="fade-left"
                data-aos-anchor-placement="top-center"
                data-aos-duration={1500}
              >
                <h2>
                  <div className="fill_text">Global Healthcare</div>
                  <div className="fill_text">Access</div>
                </h2>
                <hr className="globalbox-line" />
                <p>
                  Seamless access to global healthcare services and
                  professionals.
                </p>
              </div>
            </div>
            <div className="text-center">
              <img
                src="/assets/img/aero2.svg"
                alt="arrowbutton"
                className="arrowbutton my_animation"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
