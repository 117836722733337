import React from "react";

export const News = () => {
  return (
    <>
  <section className="logos  bg_dark p80 text-center text-white">
  <div className="container">
    <h5 className="mb-5 fw400">As seen on</h5>
    <marquee>
      <img src="/assets/img/logos/l1.png" alt="logo" className />
      <img src="/assets/img/logos/l2.png" alt="logo" className />
      <img src="/assets/img/logos/l3.png" alt="logo" className />
      <img src="/assets/img/logos/l4.png" alt="logo" className />
      <img src="/assets/img/logos/l5.png" alt="logo" className />
      <img src="/assets/img/logos/l6.png" alt="logo" className />
      <img src="/assets/img/logos/l7.png" alt="logo" className />
      <img src="/assets/img/logos/l8.png" alt="logo" className />
      <img src="/assets/img/logos/l9.png" alt="logo" className />
      <img src="/assets/img/logos/l10.png" alt="logo" className />
      <img src="/assets/img/logos/l11.png" alt="logo" className />
      <img src="/assets/img/logos/l12.png" alt="logo" className />
    </marquee>
  </div>
</section>
<section className="healix   text-center text-white">
  <div className="container">
    <h1 className=" mb-5">
      {" "}
      <span className="d-inline-flex align-items-center ">
        <img src="/assets/img/l-white.png" alt="logo" className="" /> The Healix
        Platform
      </span>
    </h1>
    <img src="/assets/img/phone.png" alt="logo" className="w100" />
  </div>
</section>


    </>
  );
};
